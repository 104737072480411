@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

@keyframes grow-animation {
    0% {
        opacity: 0;
        transform: scale(.3);
      }
      50% {
        opacity: 1;
        transform: scale(1.05);
      }
      70% { transform: scale(.9); }
      100% { transform: scale(1); }
}

.homepage-layout-image-block-background {
    background-color: $white;

    .homepage-layout-image-block-wrapper {
        gap: 40px;
        padding: 0 0 48px 0;
        margin: 0;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            gap: 80px;
            max-width: 1400px;
            margin: 0 80px;
            padding: 80px 0;
        }

        .homepage-layout-image-block-image {
            width: 100%;
            max-height: 375px;
            object-fit: cover;

            @include media-breakpoint-up(md) {
                max-width: 540px;
                max-height: 540px;
                border-radius: 16px;
            }
        }

        .homepage-layout-image-block-container {
            gap: 32px;
            padding: 0;
            max-width: 520px;

            @include media-breakpoint-down(md) {
                padding: 0 24px;
                max-width: 100%;
            }
        }

        .homepage-layout-image-block-list,
        .homepage-layout-image-block-buttons {
            gap: 16px;
        }

        .homepage-layout-image-block-primary {
            height: 44px;
            padding: 10px 16px;
            color: $white;
            font-family: $font-qualion;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            border-radius: 4px;
            background-color: $dark-green;
            text-decoration: none !important;

            &:hover {
                background-color: $light-green-tertiary;
            }

            @include media-breakpoint-up(md) {
                height: 56px;
                padding: 16px 24px;
            }
        }

        .homepage-layout-image-block-secondary {
            height: 44px;
            padding: 10px 16px;
            color: $dark-green;
            font-family: $font-qualion;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            border-radius: 4px;
            border: 2px solid $dark-green;
            text-decoration: none !important;

            &:hover {
                border-color: $light-green-tertiary;
                color: $light-green-tertiary;
                opacity: 0.5;
            }

            @include media-breakpoint-up(md) {
                height: 56px;
                padding: 16px 24px;
            }
        }

        .homepage-layout-image-block-small-print {
            color: $neutral-70;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .homepage-layout-image-block-list-item {
            border-radius: 8px;
            background-color: $neutral-10;
            padding: 16px 24px;
            gap: 16px;
        }

        .homepage-layout-image-block-list-item-label {
            color: $neutral-70;
            font-family: $font-qualion;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .experience-commerce_assets-homepageImageBlockItem {
            width: 100%;
        }

        .homepage-layout-image-block-list {
            &.numbers {
                .homepage-layout-image-block-item-icon-text {
                    display: flex;
                }
            }

            &.icons {
                .homepage-layout-image-block-item-icon-image {
                    display: flex;
                }
            }

            &.checkmarks {
                .homepage-layout-image-block-item-icon-checkmark {
                    display: flex;
                }
            }
        }

        .homepage-layout-image-block-item-icon-checkmark {
            display: none;
            width: 25px;
            height: 25px;
        }

        .homepage-layout-image-block-item-icon-checkmark-circle {
            border: 1px solid $dark-green;
            border-radius: 50%;
        }

        .homepage-layout-image-block-item-icon-checkmark-check {
            top: -1px;

            &.check-animation {
                animation-name: grow-animation;
                animation-duration: 2s;
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;
            }
        }

        .homepage-layout-image-block-item-icon-text {
            display: none;
            color: $neutral-70;
            font-family: $font-qualion;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
        }

        .homepage-layout-image-block-item-icon-image {
            display: none;
            width: 48px;
            height: 48px;
        }
    }
}